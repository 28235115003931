/* Class for all components styling */

/* Default Create React App font which I've kept, merged from index.css which has now been refactored out */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Setting the size of the free text entry text box */
.freeTextEntry {
  width: 250px;
  height: 50px; 
}

/* Each element of the navbar list */
.navbar li {
  /* Add some padding to space things out */
  padding: 10px;
}

/* Specifically the text on the navbar 'Stylometric Analysis' we want to float all the way left but 10px all sides padded */
.navbar li p {
  float: left;
  padding: 10px
}

/* For links on the navbar */
.navbar li a {
  background-color: blueviolet;
  /* Smooth corners */
  border-radius: 5px;
  /* White writing */
  color: white;
  /* These links are on the right hand side so float right */
  float: right;
  padding: 10px;
  /* Stops annoying underlining of visited URLs and similar */
  text-decoration: none;
}

/* Setting right margin 50px */
.navbar ul {
  list-style-type: none;
  margin: 0px 50px 0px 0px;
}

/* Sample and comparison information */
.profileInformation {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 25px;
  padding-bottom: 25px;
}

/* Div for most of the metrics being displayed and to align the sample and comparison against each other properly */
.profileStatistics {
  align-items: center;
  /* Align the two divs next to each other horizontally so they don't differ in horizontal start point when text length varies */
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  float: left;
  justify-content: center;
  padding-bottom: 10px;
  padding-left: 50px;
  padding-right: 25px;
  margin: 0 auto;
}

/* Setting the colour of discrepancy windows and values */
.profileStatisticsDiscrepancy {
  color: red;
}

/* Green windows if no discrepancy is discovered */
.profileStatisticsNoDiscrepancy {
  color: green;
}

/* Buttons and text on the Presets and Upload components */
.selects {
  float: left;
  margin-right: 50px;
  padding-bottom: 50px;
}

/* Specifically for Upload page */
.smallParagraph {
  font-size: 16px;
}

/* Main content */
.titleSubtitle {
  align-items: center;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
  text-align: center;
}

/* Button styling across Home and such */
.titleSubtitle button {
  background-color: blueviolet;
  border-color: blueviolet;
  border-radius: 5px;
  color: white;
  font-size: calc(10px + 2vmin);
  padding: 10px;
}

/* Ordering and spacing the buttons */
.titleSubtitle li {
  float: left;
  padding: 20px;
}

/* Link styling */
.titleSubtitle li a {
  background-color: blueviolet;
  border-radius: 5px;
  color: white;
  padding: 10px;
  text-decoration: none;
}

/* List styling */
.titleSubtitle ul {
  list-style-type: none;
  margin: 0px 50px 0px 0px;
}

/* Styling for ReviewAnalysis components */
.titleSubtitleReviewAnalysis {
  align-items: center;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  text-align: center;
}

/* Fixing sizes of React Charts elements or they will quite literally take the whole page and phase over any elements */
.wrapperChart {
  height: 500px;
  width: 500px;
}

/* Ensuring the multiple divs for profileStatistics if comparision are aligned properly */
.wrapperProfileStatistics {
  align-items: center;
  display: flex;
  justify-content: center;
}

/* Wrapping the multiple selects */
.wrapperSelects {
  display: flex;
  justify-content: center;
}
